import amplitude from 'amplitude-js/amplitude';
import {AMPLITUDE_KEY, APP_VERSION, RETAILER_NAME} from './config'

export const initAmplitude = () => {
    amplitude.getInstance().init(AMPLITUDE_KEY, null, {
        // logLevel: 'INFO',
        platform: 'Web - Preview',
        retailer: RETAILER_NAME
    });
    amplitude.getInstance().setVersionName(APP_VERSION);
};

export const logSocialShareViewEvent = (name) => {
    amplitude.getInstance().logEvent("Social Share View",  {"Experience Name": name});
};

export const logDownloadAppClickEvent = (from, platform, url) => {
    amplitude.getInstance().logEvent("Download App Click",  {"Retailer": "ShopRite", "From": from, "To": platform, "Url": url});
};

export const logSocialLinkClickEvent = (name) => {
    amplitude.getInstance().logEvent("Social Link Click",  {"Experience Name": name});
};

export const logCtaClickEvent = (name, id, brandName, url) => {
    amplitude.getInstance().logEvent("Conclusion Link Click",  {"Campaign Name": name, "Campaign Id": id, "Brand Name": brandName, "Actual URL": url});
};

export const getAmplitudeDeviceId = () => {
    return amplitude.getInstance().options.deviceId;
};
