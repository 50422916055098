import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {API_URL} from './config'
import axios from 'axios';
import './App.css';
import CampaignPage from './pages/CampaignPage'


function App(props) {

  console.log('Starting the app...');
  useEffect(() => {
    console.log('Starting the app - useEffect');
    console.log(API_URL);
    axios.defaults.baseURL = API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response && 401 === error.response.status) {
        console.log('Error 401')
      } else {
        return Promise.reject(error);
      }
    });
  });

  return (
        <Router>
          <div>
            <Switch>
              <Route path="/:token" component={CampaignPage}/>
            </Switch>
          </div>
        </Router>
  );
}

export default App;

