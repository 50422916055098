import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { initAmplitude, logSocialLinkClickEvent, logSocialShareViewEvent, logCtaClickEvent, getAmplitudeDeviceId, logDownloadAppClickEvent } from '../amplitude';
import { Campaign, CampaignPreviewCard} from 'dabbl-react-components';
import {API_URL, RETAILER_NAME} from "../config";
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';

function CampaignPage(props) {
    console.log('Starting the CampaignPage...');
    const [campaign, setCampaign] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isPreviewShown, setIsPreviewShown] = useState(false);
    const [downloadLinkOnConclusion, setDownloadLinkOnConclusion] = useState('https://downtimedollars.shoprite.com');
    const [requestError, setRequestError] = useState('');

    console.log('Starting the CampaignPage2...');
    useEffect(() => {

        console.log('Starting the CampaignPage - useEffect');
        initAmplitude();
        const token = props.match.params.token
        console.log(props)
        if (!token) {
            setRequestError("No Experience specified for Preview")
        }
        axios.get(`${API_URL}/pub/v1/campaignPreview/${token}`)
            .then(result => {
                console.log(result)
                if (result && result.status === 200 && !result.data.error) {
                    setCampaign(result.data);
                    setIsLoading(false)
                    if (result.data) {
                        trackPreviewView(result.data)
                    }
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
        window.onpopstate = (e) => {
            if (!isPreviewShown){ return }
            setIsPreviewShown(false)
        }
    }, [props.match.params]);

    function trackSocialShare(campaignId, action){
        const deviceId = getAmplitudeDeviceId()
        let url = `${API_URL}/app/v1/pub/campaign/social?campaignId=${campaignId}&action=${action}`
            if (deviceId) {
                url+=`&deviceId=${deviceId}`
            }
        axios.patch(`${url}`)
            .then(result => {
                console.log(result)
            }).catch(e => {
                console.log(e.message);
        });
    }

    function handlePreviewClick() {
        window.history.pushState({}, null, '#content');
        setIsPreviewShown(true)
    }

    function backToPreview(){
        window.history.back();
    }

    function onSkipClick() {
        if (downloadLinkOnConclusion) {
            return
        }
        backToPreview()
    }

    function trackPreviewView(campaign){
        logSocialLinkClickEvent(campaign.name)
        trackSocialShare(campaign.id, 'start')
    }

    function trackConclusionView(campaign){
        logSocialShareViewEvent(campaign.name)
        trackSocialShare(campaign.id, 'end')
    }

    function trackDownloadAppClick(from, platform, url){
        logDownloadAppClickEvent(from, platform, url)
    }

    function onConclusionLinkClick(url){
        logCtaClickEvent(campaign.name, campaign.id, campaign.brand.name, url)
        trackSocialShare(campaign.id, 'click', getAmplitudeDeviceId())
    }
    if (requestError) {
        return <div className='vertical-center' style={{height: "100%"}}>{requestError}</div>
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (<div className='vertical-center' style={{height: "100%"}}>
            {isPreviewShown ? (
                <div className="PreviewCampaign">
                    <Campaign
                        campaign={campaign}
                        retailerName={RETAILER_NAME}
                        hideTotalValue
                        showToast={ !downloadLinkOnConclusion }
                        usePoints={true}
                        hideToastCopy={true}
                        downloadLinkOnConclusion={downloadLinkOnConclusion}
                        onCloseCampaign={() => backToPreview()}
                        onShareButtonClick={(network) => {}}
                        onSkipShareCampaign={()=>onSkipClick()}
                        onAllEngagementsAnswered={()=> downloadLinkOnConclusion && trackConclusionView(campaign)}
                        onConclusionLinkClick={(url)=>onConclusionLinkClick(url)}
                        onDownloadAppClick={(from, platform, url) => {trackDownloadAppClick(from, platform, url)}}
                    />
                </div>
            ) : (
                <div className="PreviewCampaign_PreviewCard">
                    <Container>
                        <Row>
                            <Col xs={12}
                            sm={{size: 10, offset: 1}}
                            md={{size: 8,  offset: 2}}
                                lg={{size: 8, offset: 2}}
                            xl={{size: 6, offset: 3}}
                            >
                    <CampaignPreviewCard
                        brandName={campaign.brand.name}
                        retailerName={RETAILER_NAME}
                        downloadLink={downloadLinkOnConclusion}
                        totalValue={campaign.totalValue}
                        imgSrc={campaign.feedimageurl}
                        isNewDesign={true}
                        usePoints={true}
                        text={campaign.feedtext}
                        textSecondary={downloadLinkOnConclusion === undefined ? `from ${campaign.brand.name}` : `provided by ${campaign.brand.name}`}
                        onClick={() => handlePreviewClick()}
                        onDownloadAppClick={(from, platform, url) => {trackDownloadAppClick(from, platform, url)}}
                    />
                            </Col></Row></Container>
                </div>
            )
            }
        </div>
    );
}

export default CampaignPage;